import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <>
      <StaticQuery
        query={detailsQuery}
        render={(data) => {
          const metaDescription =
            description || data.site.siteMetadata.description
          return (
            <Helmet
              htmlAttributes={{
                lang
              }}
              title={title}
              // titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              meta={[
                {
                  name: `description`,
                  content: metaDescription
                },
                {
                  property: `og:title`,
                  content: title
                },
                {
                  property: `og:description`,
                  content: metaDescription
                },
                {
                  property: `og:type`,
                  content: `website`
                },
                {
                  name: `twitter:card`,
                  content: `summary`
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author
                },
                {
                  name: `twitter:title`,
                  content: title
                },
                {
                  name: `twitter:description`,
                  content: metaDescription
                }
              ]
                .concat(
                  keywords && keywords.length > 0 && Array.isArray(keywords)
                    ? {
                        name: `keywords`,
                        content: keywords.join(',') // keywords= "string1,string2,string3"
                      }
                    : []
                )
                .concat(meta)}
            >
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
                rel="stylesheet"
              />
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-96W7VT8143"
              ></script>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'G-96W7VT8143');
          `
                }}
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `gtag('config', 'AW-342968788/xLt9CP6ourYDENSTxaMB', { 'phone_conversion_number': '312-319-4766' })`
                }}
              />
              <script
                charset="utf-8"
                type="text/javascript"
                src="//js.hsforms.net/forms/v2.js"
              ></script>
            </Helmet>
          )
        }}
      />
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
